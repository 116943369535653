import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import AreaDePractica from "../components/areaDePractica"

const LayoutReplacement = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  padding: 1.1rem 1.45rem;
  max-width: 1320px;

  @media only screen and (min-width: 1200px) {
    padding: 0px 0px 0px 60px;
  }

  @media only screen and (min-width: 1500px) {
    margin: 0 auto;
  }
`

const Title = styled.h1`
  @media only screen and (min-width: 1500px) {
    margin-left: 70px;
  }
`

const AdpsContainer = styled.div`
  margin-top: 75px;

  @media only screen and (min-width: 765px) {
    margin-left: 120px;
    margin-top: 120px;
  }
`

const AreasDePractica = ({ data }) => {
  const areas = [
    {
      titulo: "Litigación y Procuraduría",
      descripcion:
        "Promoción y defensa en las áreas civiles, comerciales, laborales y contenciosos administrativos. Además del seguimiento, evaluación y diagnóstico de juicios ya iniciados.",
      img: data.litigaciones.childImageSharp.fluid,
    },
    {
      titulo:
        "Asesoría jurídica Civil, Sucesiones, Comercial, Laboral y Administrativo",
      descripcion:
        "Primeros auxilios legales, consultas jurídicas y dictámenes, correspondencia en español, francés, alemán e inglés.",
      img: data.asesoria.childImageSharp.fluid,
    },
    {
      titulo: "Redacción y Análisis de Contratos",
      descripcion:
        "“Redacción, análisis y evaluación de contratos civiles, comerciales y laborales.",
      img: data.redaccion.childImageSharp.fluid,
    },
    {
      titulo: "Asesoría en Inversiones",
      descripcion:
        "Análisis del marco jurídico y tributario de inversiones locales y extranjeras.",
      img: data.inversiones.childImageSharp.fluid,
    },
    {
      titulo: "Derecho Sucesorio",
      descripcion:
        "Asesoría jurídica, litigación y procuraduría en asuntos sucesorios.",
      img: data.sucesorio.childImageSharp.fluid,
    },
    {
      titulo: "Derecho Inmobiliario",
      descripcion:
        "Análisis y evaluación de títulos de propiedad. Litigación y gestión administrativa en mensuras, deslindes, superposición de títulos, fraccionamientos, loteamientos, estudio de títulos ante los registros públicos.",
      img: data.inmobiliario.childImageSharp.fluid,
    },
    {
      titulo: "Gestión Ganadera",
      descripcion:
        "Auditoria de establecimientos ganaderos, asesoría legal, asesoría integral, solicitud y gestión de marcas y señales.",
      img: data.ganaderia.childImageSharp.fluid,
    },
    {
      titulo: "Propiedad Intelectual",
      descripcion:
        "Inscripción de marcas, de patentes industriales y de invención, presentación y contestación de oposiciones.",
      img: data.intelectual.childImageSharp.fluid,
    },
  ]
  const divAreas = areas.map(area => <AreaDePractica area={area} />)
  return (
    <div>
      <Layout noContain>
        <SEO title="Áreas de práctica" />
        <LayoutReplacement>
          <Title>Áreas de práctica</Title>
          <AdpsContainer>{divAreas}</AdpsContainer>
        </LayoutReplacement>
      </Layout>
    </div>
  )
}

export default AreasDePractica

export const query = graphql`
  query ADPQuery {
    asesoria: file(relativePath: { eq: "asesoria.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    inmobiliario: file(relativePath: { eq: "inmobiliario.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ganaderia: file(relativePath: { eq: "ganaderia.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    intelectual: file(relativePath: { eq: "intelectual.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    inversiones: file(relativePath: { eq: "inversiones.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    litigaciones: file(relativePath: { eq: "litigaciones.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    redaccion: file(relativePath: { eq: "redaccion.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sucesorio: file(relativePath: { eq: "sucesorio.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
